import React from 'react'
import Layout from '../../components/layout'
import songArt from '../../images/song_art/empty_world.jpg'
import { Link } from 'gatsby'

const EmptyWorld = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">hey where did everybody go - was it something I said?</span>
        </div>
      </div>
    </section>
    <section className="section song empty_world has-text-centered">
        <img src={songArt} className="cover" alt="Planet and Sky album cover" />
    </section>
  </Layout>
)

export default EmptyWorld
